import React, { useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import {
  removeFavoritedDealer,
  addFavoritedDealer,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { Dealer } from "../../../global"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { LanguageContext } from "../../../contexts/Language"
import { Link } from "../../atoms/Link"
import { openContactDealerModal } from "../../../contexts/Contact/actions"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LocationContext } from "../../../contexts/Location"
import moment from "moment"
import { slugify } from "../../../helpers"

const DealerTooltip: React.FC<{
  dealer: Dealer
  i: number
}> = ({ dealer, i }) => {
  const [_contactState, contactDispatch] = useContext(ContactContext)
  const [{ favoritedDealer }, dispatch] = useContext(FavoritesContext)
  const { language, _ } = useContext(LanguageContext)
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const [{ zip }] = useContext(LocationContext)
  const isFavorite =
    favoritedDealer && favoritedDealer.DealerCode === dealer.DealerCode

  const currentNow = moment.now()

  let isOpenNow = false
  const todaysHours =
    dealer.Departments?.length > 0
      ? dealer.Departments[0].hoursOfOperation.filter(
          (item: { DayOfWeek: string }) =>
            item.DayOfWeek === moment(currentNow).format("dddd"),
        )
      : null

  if (todaysHours && todaysHours[0] && todaysHours[0].OpeningTime) {
    isOpenNow = isOpen(
      todaysHours[0].OpeningTime,
      todaysHours[0].ClosingTime,
      dealer.State === "AL" ? "America/Chicago" : "America/New_York",
    )
  }

  function isOpen(openTime: string, closeTime: string, timezone: string) {
    //const now = moment.now()
    const date = moment(currentNow).format("YYYY-MM-DD")
    const storeOpenTime = moment(
      date + " " + openTime,
      "YYYY-MM-DD h:mmA",
      timezone,
    )
    const storeCloseTime = moment(
      date + " " + closeTime,
      "YYYY-MM-DD h:mmA",
      timezone,
    )

    return moment(currentNow).isBetween(
      storeOpenTime,
      storeCloseTime,
      null,
      "[)",
    )
  }

  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {},
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      dealer_name: dealer?.Name,
      dealer_code: dealer?.DealerCode,
      page_type: tealPageData.page_type,
      customer_zip: zip,
      ...moreData,
    })
  }
  return (
    <div css={[tw`p-2 pt-6`]}>
      <div css={[tw`flex justify-between w-full items-start`]}>
        <Link to={`/dealers/${slugify(dealer.Name)}`} css={[tw`flex pt-3`]}>
          {i != null && (
            <span css={[tw`pr-1.5 text-xl font-semibold`]}>{`${i + 1}. `}</span>
          )}
          <span
            css={[
              tw`pr-2 text-xl font-semibold hover:(underline cursor-pointer)`,
            ]}
          >
            {dealer.Name}
          </span>
        </Link>
        <button
          onClick={() => {
            if (isFavorite) {
              dispatch(removeFavoritedDealer())
            } else {
              dispatch(addFavoritedDealer(dealer))
              dispatch(setFavoriteMessage("dealer"))
            }
          }}
          css={[tw``, tw`focus-visible:outline-gray-50 pt-3`]}
          aria-label={
            isFavorite ? `Unfavorite ${dealer.Name}` : `Favorite ${dealer.Name}`
          }
        >
          <Icon.Heart
            color={isFavorite ? "red-400" : "gray-900"}
            css={[tw`h-8`]}
            filled={isFavorite}
          />
        </button>
      </div>
      {isOpenNow && (
        <span css={[tw`ml-6 font-semibold`]}>{_("Open Today")}</span>
      )}
      <div>
        <Link
          to={`https://www.google.com/maps/search/?api=1&query=${dealer.Name} ${dealer.Address1} ${dealer.City}, ${dealer.State} ${dealer.Zip}`}
          css={[
            tw`p-0 mb-1 mt-3 ml-6 hover:(underline)`,
            tw`focus-visible:outline-gray-50`,
          ]}
          onClick={() => handleTealEvent("get_directions")}
        >
          {dealer.Distance} {_("mi away - Directions")}
        </Link>
      </div>
      <Button
        primary
        css={[tw`text-xs my-2 flex w-full items-center justify-center`]}
        onClick={() => {
          handleTealEvent("contact_dealer_rest")
          contactDispatch(openContactDealerModal(dealer))
        }}
        analytics-id={i && `contact:dealers:${i}`}
      >
        {_("Contact Dealer")}
      </Button>
    </div>
  )
}

export default DealerTooltip
