import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react"
import Map, { Marker, Popup, MapRef, ViewStateChangeEvent } from "react-map-gl"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { LocationContext } from "../../../contexts/Location"
import { setSelectedDealer } from "../../../contexts/Location/actions"
import DealerTooltip from "./DealerTooltip"
import { DealerMapProps } from "./DealerMap.d"
import { css, Global } from "@emotion/react"
import tw from "twin.macro"

const DealerMap: React.FC<DealerMapProps> = ({
  dealers,
  allowPan,
  radius,
  mapContainerStyle,
  selectedMobileView = null,
  ...remainingProps
}) => {
  const [mapHeight] = useState(mapContainerStyle?.height || "100%")
  const [{ selectedDealer }, dispatch] = useContext(LocationContext)
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  )
  const mapRef = useRef<MapRef | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const [viewport, setViewport] = useState({
    latitude: 32.7794,
    longitude: -86.8287,
    zoom: 6,
    bearing: 0,
    pitch: 0,
  })

  // Setup resize observer
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize()
        fitMapToBounds()
      }
    }

    // Create resize observer
    resizeObserverRef.current = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentRect.height > 0) {
          handleResize()
        }
      }
    })

    // Observe container
    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current)
    }

    // Cleanup
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect()
      }
    }
  }, [])

  // Setup visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && mapRef.current) {
        setTimeout(() => {
          mapRef.current?.resize()
          fitMapToBounds()
        }, 100)
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  const fitMapToBounds = useCallback(() => {
    if (!dealers?.length || !mapRef.current || !mapLoaded) return

    try {
      mapRef.current.resize()

      setTimeout(() => {
        if (!mapRef.current) return

        const bounds = new mapboxgl.LngLatBounds()

        dealers.forEach(dealer => {
          bounds.extend([dealer.Longitude, dealer.Latitude])
        })

        const sw = bounds.getSouthWest()
        const ne = bounds.getNorthEast()
        const latSpan = ne.lat - sw.lat
        const lngSpan = ne.lng - sw.lng

        bounds.extend([sw.lng - lngSpan * 0.2, sw.lat - latSpan * 0.2])
        bounds.extend([ne.lng + lngSpan * 0.2, ne.lat + latSpan * 0.2])

        mapRef.current.fitBounds(bounds, {
          padding: {
            top: 150,
            bottom: 50,
            left: 50,
            right: 50,
          },
          duration: 1000,
          maxZoom: 15,
          offset: [0, -100],
        })

        if (dealers.length === 1) {
          setTimeout(() => {
            if (mapRef.current) {
              mapRef.current.setZoom(12)
              mapRef.current.panTo(
                [dealers[0].Longitude, dealers[0].Latitude],
                {
                  offset: [0, -100],
                },
              )
            }
          }, 100)
        }
      }, 100)
    } catch (error) {
      console.error("Error fitting bounds:", error)
    }
  }, [dealers, mapLoaded])

  useEffect(() => {
    if (mapLoaded) {
      fitMapToBounds()
    }
  }, [dealers, selectedMobileView, mapLoaded, fitMapToBounds])

  const handleMapLoad = useCallback(() => {
    setMapLoaded(true)
    if (mapRef.current) {
      mapRef.current.resize()
      fitMapToBounds()
    }
  }, [fitMapToBounds])

  const handleMarkerClick = (index: number) => {
    setSelectedMarkerIndex(index)
    dispatch(setSelectedDealer(dealers[index].Id))

    if (mapRef.current) {
      // Get current zoom level
      const currentZoom = mapRef.current.getZoom()

      mapRef.current.flyTo({
        center: [dealers[index].Longitude, dealers[index].Latitude],
        zoom: currentZoom, // Maintain current zoom level
        duration: 1000,
        offset: [0, -100],
      })
    }
  }

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: mapHeight,
        position: "relative",
        minHeight: "400px", // Ensure minimum height
      }}
    >
      <Global
        styles={css`
          .mapboxgl-popup-close-button {
            ${tw`text-3xl w-8 h-8 opacity-60`}
          }
          .mapboxgl-popup-content {
            ${tw`pt-6`}
          }
        `}
      />
      <Map
        {...viewport}
        ref={mapRef}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={process.env.GATSBY_MAPBOX_API_KEY}
        onMove={evt => setViewport(evt.viewState)}
        onLoad={handleMapLoad}
        interactive={allowPan}
        onClick={() => setSelectedMarkerIndex(null)}
        reuseMaps
        {...remainingProps}
      >
        {dealers?.map((dealer, i) => (
          <React.Fragment key={dealer.Id || i}>
            <Marker
              latitude={dealer.Latitude}
              longitude={dealer.Longitude}
              anchor="bottom"
              onClick={e => {
                e.originalEvent.stopPropagation()
                handleMarkerClick(i)
              }}
            >
              <div
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "20px",
                  height: "30px",
                }}
              >
                <img
                  src={
                    selectedDealer && selectedDealer.Id === dealer.Id
                      ? "/marker_red.svg"
                      : "/marker_black.svg"
                  }
                  alt="marker"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                {radius && (
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      color: "white",
                      fontSize: "11.5px",
                      fontWeight: "bold",
                      textAlign: "center",
                      lineHeight: "1",
                    }}
                  >
                    {i + 1}
                  </div>
                )}
              </div>
            </Marker>

            {selectedMarkerIndex === i && (
              <Popup
                latitude={dealer.Latitude}
                longitude={dealer.Longitude}
                closeButton={true}
                closeOnClick={false}
                onClose={() => setSelectedMarkerIndex(null)}
                anchor="bottom"
                offset={[0, -15]}
              >
                <DealerTooltip dealer={dealer} i={i} />
              </Popup>
            )}
          </React.Fragment>
        ))}
      </Map>
    </div>
  )
}

export default React.memo(DealerMap)
